<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3"></nav>
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <div>
                <h1>Lennox Catalog Order</h1>

                <div v-show="newCatalogOrders.length > 0">
                    <div class="alert alert-info">
                        <div v-for="newOrderID in newCatalogOrders" v-bind:key="newOrderID">
                            Order created #<router-link :to="`/tracker/${ newOrderID }`">{{ newOrderID }}</router-link>
                        </div>
                    </div>
                </div>

                <div class='row'>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>PO #</label>
                        <input v-model="poNumber" type='text' class='form-control'>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>CRDC</label>
                        <select class="cdi_select form-control" v-model="selectedCrdc">
                            <option v-for="(distributor, crdcID) in allCrdcDistributors" v-bind:key="crdcID" :value="crdcID">
                                {{ distributor }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                        <label>Scheduled Ship</label>
                        <DatePicker v-model="scheduledShip" :inputAttributes="{class: 'form-control'}" />
                    </div>
                </div>

                <button class='btn btn-primary mt-2' @click="placeOrder">
                    Place Order
                </button>

                <div class="spacing_top form-inline">
                    <div v-for="catalogCurb in allCatalogCurbs" v-bind:key="catalogCurb.curb_id" class="inline_grid">
                        <input type="text" class="form-control" v-model="catalogCurb.val">
                        <label>{{ catalogCurb.catalog_num }}</label> <small>{{ catalogCurb.nomenclature }}</small>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import { store } from '@/store/Tool.store';
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "CatalogOrder",
        components: {DatePicker},
        data() {
            return {
                allCrdcDistributors: [],
                allCatalogCurbs: [],
                newCatalogOrders: [],
                poNumber: null,
                selectedCrdc: null,
                scheduledShip: null
            }
        },
        created() {
            this.load();
        },
        methods: {
            load: function () {
                let localThis = this;
                store.api('/tracker/catalog_order_form_json').then(function (results) {
                    localThis.allCatalogCurbs = results.lennox_curbs;
                    localThis.allCrdcDistributors = results.crdc_distributors;
                });
            },
            placeOrder: function () {
                if (!this.selectedCrdc) {
                    this.$appStore.errorBox('Select CRDC');

                    return;
                } else if (!this.scheduledShip) {
                    this.$appStore.errorBox('Enter scheduled ship date');

                    return;
                }

                let options = {
                    'params[distributor_po]': this.poNumber,
                    'params[distributor_id]': this.selectedCrdc,
                    'params[scheduled_ship]': this.scheduledShip
                };

                for (let i = 0; i < this.allCatalogCurbs.length; i++) {
                    options['params[curbs][' + this.allCatalogCurbs[i].curb_id + ']'] = this.allCatalogCurbs[i].val;
                    this.allCatalogCurbs[i].val = null; // Reset value in loop after we've appended to the options
                }

                store.api('/tracker/add_catalog_order', options).then(results => this.newCatalogOrders.push(results.tracker_id));

                this.poNumber = null;
                this.selectedCrdc = null;
                this.scheduledShip = null;
            }
        },
    }
</script>

<style scoped>

</style>